<template>
  <div>
    <el-popover
      v-model="isPopover"
      placement="left"
      trigger="click"
    >
      <el-form
        ref="form"
        v-loading="listLoading"
        :model="filterOptions"
        label-position="top"
      >
        <el-form-item
          :label="$t('field.main_type')"
          prop="mainType"
        >
          <el-select
            v-model="filterOptions.mainType"
            :placeholder="$t('dataList.selectData')"
            filterable
            clearable
            style="width:100%;"
            multiple
          >
            <el-option
              v-for="option in mainType"
              :key="option"
              :label="$t(`general.${option}`)"
              :value="option"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('field.type')"
          prop="type"
        >
          <el-select
            v-model="filterOptions.type"
            :placeholder="$t('dataList.inputType')"
            filterable
            clearable
            multiple
            style="width:100%;"
            @clear="onRemoveType"
            @remove-tag="onRemoveType"
          >
            <el-option-group
              v-for="group in typeOptions.type"
              :key="group.label"
              :label="$t(`general.${group.label}`)"
            >
              <el-option
                v-for="item in group.options"
                :key="item"
                :label="$t(`mainType.${item}`)"
                :value="item"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="!isSubTypeOptionsEmpty"
          :label="$t('field.subtype')"
          prop="subType"
        >
          <el-select
            v-model="filterOptions.subType"
            :placeholder="$t('dataList.inputSubtype')"
            filterable
            clearable
            multiple
            style="width:100%;"
          >
            <el-option-group
              v-for="group in subTypeOptions"
              :key="group.label"
              :label="$t(`mainType.${group.label}`)"
            >
              <el-option
                v-for="item in group.options"
                :key="item"
                :label="$t(`subType.${item}`)"
                :value="item"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="isAdminTeam"
          :label="$t(`accountManagement.organization`)"
          prop="org"
        >
          <el-select 
            v-model="filterOptions.org"
            :placeholder="$t('dataList.selectOrg')"
            filterable
            clearable
            style="width:100%;"
          >
            <el-option 
              v-for="item in orgOptionList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('dataList.uploadRange')"
          prop="dateRange"
        >
          <el-date-picker
            v-model="filterOptions.dateRange"
            type="daterange"
            :start-placeholder="$t('dataList.startDate')"
            :end-placeholder="$t('dataList.endDate')"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item
          :label="$t('dataList.recordRange')"
          prop="record_dateRange"
        >
          <el-date-picker
            v-model="filterOptions.record_dateRange"
            type="daterange"
            :start-placeholder="$t('dataList.startDate')"
            :end-placeholder="$t('dataList.endDate')"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item
          :label="$t('field.user_id')"
          prop="userId"
        >
          <el-input
            v-model="filterOptions.userId"
            :placeholder="$t('dataList.inputUserId')"
            suffix-icon="el-icon-search"
            clearable
          />
        </el-form-item>
        <el-form-item style="text-align: right;">
          <el-button
            class="buttonSecondaryReverse"
            @click="handleFilter"
          >
            {{ $t('general.Filter') }}
          </el-button>
        </el-form-item>
      </el-form>
      <el-button
        slot="reference"
        icon="el-icon-s-operation"
        type="text"
        class="headerTextColor"
      >
        {{ $t('general.Filter') }}
        <em class="el-icon-arrow-down el-icon--right" />
      </el-button>
    </el-popover>
    <template v-if="isFilterChanged">
      <el-divider direction="vertical" />
      <el-button
        size="small"
        icon="el-icon-download"
        class="buttonSecondaryReverse"
        :disabled="totalPage === 0"
        :loading="fileLoading"
        @click="downloadExcel"
      >
        CSV
      </el-button>
      <el-button
        size="small"
        icon="el-icon-close"
        class="buttonSecondaryReverse"
        @click="cleanFilter"
      >
        {{ $t('general.Clean') }}{{ $t('general.Filter') }}
      </el-button>
      <el-button
        v-if="!isSubadmin"
        size="small"
        type="danger"
        icon="el-icon-delete"
        class="delete-button"
        :disabled="totalPage === 0"
        @click="handleDeleteButton"
      >
        {{ $t('general.Batch')+ $t('general.Delete') }}
      </el-button>
    </template>
  </div>
</template>

<script>
import { MemberRole } from '@/Mixins'
import { getDatatype, downloadExcelData } from '@/api/data'
export default {
  name: 'SearchFilter',
  mixins: [MemberRole],
  props: {
    filterOptions: {
      type: Object,
      default: () => ({})
    },
    mainType: {
      type: Array,
      default: () => ({})
    },
    orgOptionList: {
      type: Array,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    },
    totalPage: {
      type: Number,
      default: 0
    },
    deleteDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      listLoading: false,
      allOptions: [], // 主類型所有選項
      isPopover: false,
      isFilterChanged: false,
      fileLoading: false
    }
  },
  computed: {
    selectedMainType() {
      return this.filterOptions.mainType
    }, 
    typeOptions() {
      const typeOptions = {}
      const options = Object.entries(this.allOptions).filter((item) =>
        this.selectedMainType.includes(item[0]) ||
        this.selectedMainType.length === 0
      )
      typeOptions.type = options.map((item) => ({
        label: item[0],
        options: Object.keys(item[1]).map((item) => item)
      }))
      typeOptions.subType = options.flatMap((item) => {
        return Object.entries(item[1]).map((item) => ({
          label: item[0],
          options: item[1]
        }))
      })
      return typeOptions
    },
    subTypeOptions() {
      return this.typeOptions.subType.filter((item) =>
        this.filterOptions.type.includes(item.label)
      )
    },
    isSubTypeOptionsEmpty() {
      return this.subTypeOptions.flatMap((item) => item.options).length === 0
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.fetchDataType()
    },
    async fetchDataType() {
      try {
        this.allOptions = (await getDatatype()).data
      } catch (error) {
        console.error('getDatatype-error: ', error)
      } 
    },
    onRemoveType() {
      this.filterOptions.subType = []
    },
    handleFilter() {
      this.listLoading = true
      this.isFilterChanged = true
      this.$emit('update')
      this.listLoading = false
      this.isPopover = false
    },
    cleanFilter() {
      this.listLoading = true
      this.isFilterChanged = false
      this.$refs['form'].resetFields()
      this.$emit('update')
      this.listLoading = false
    },
    downloadExcel() {
      this.fileLoading = true
      downloadExcelData(this.params)
        .then((res) => {
          const url = window.URL.createObjectURL(res)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'date.zip')
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.fileLoading = false
        })
    },
    async handleDeleteButton () {
      this.$emit('update:deleteDialogVisible', true)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.el-form--label-top .el-form-item__label{
  padding: 0 !important;
}
.el-select-group .el-select-dropdown__item {
  padding-left: 30px;
}
.el-select-group__wrap::after {
  display: none;
}
</style>
