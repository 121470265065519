<template>
  <div class="data-container">
    <div class="data-container-main">
      <div class="data-bar">
        <span class="control-left">
          <span>{{ $t('navbar.DataList') }}</span>
          <span class="control-text">
            <span>{{ $t('dataList.totalCount') }} : </span>
            <span>{{ formatToLocaleString(totalPage) }}</span>
          </span>
        </span>
        <span class="delete-button-container">
          <SearchFilter
            ref="searchFilter"
            :filter-options.sync="filterOptions"
            :main-type="mainType"
            :org-option-list="orgOptionList"
            :params="params"
            :total-page="totalPage"
            :delete-dialog-visible.sync="deleteDialogVisible"
            @update="handleFilter"
          />
          <el-popover
            popover-class="batch-delete-content"
            placement="bottom-end"
            width="200"
            trigger="click"
          >
            <div>
              <div>{{ $t('dataList.batchDeleteTitle') }}</div>
              <div>{{ $t('dataList.batchDeleteDescription') }}</div>
            </div>
            <el-button
              slot="reference"
              type="text"
              class="batch-delete-button"
            >
              <img :src="batchDelete" class="image" alt="" aria-hidden="true">
            </el-button>
          </el-popover>
        </span>
      </div>
      <div class="data-list">
        <el-table
          v-loading="listLoading"
          stripe
          :data="tableData"
          :header-cell-style="{ 'font-size': '14px' }"
          @row-click="handleRowClick"
        >
          <el-table-column
            type="index"
            :label="$t('general.Index')"
            width="80"
          />
          <el-table-column
            v-for="(item, index) in fieldsDataWithWidth"
            :key="index"
            :prop="item.key"
            :label="$t(`field.${item.key}`)"
            :width="item.width"
          />
          <el-table-column :label="$t('field.created_at')" width="200">
            <template slot-scope="scope">
              <span>{{ UTCtoLocalTime(scope.row.created_at) }}</span>
            </template>
          </el-table-column>
          <template slot="empty">
            <el-empty :description="$t('general.NoData')" />
          </template>
        </el-table>
        <el-dialog
          :visible.sync="dialogVisible"
          :show-close="false"
          class="data-detail"
          width="50%"
          @close="deleteIds=[]"
        >
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-button
                plain
                type="info"
                style="float: right; padding: 10px"
                @click="dialogVisible = false"
              >
                {{ $t('general.Close') }}
              </el-button>
              <el-button
                v-if="!isSubadmin"
                plain
                type="danger"
                style="float: right; padding: 10px; margin-right: 10px"
                @click="handleDelete()"
              >
                {{ $t('general.Delete') }}
              </el-button>
            </div>
            <div
              v-for="data in selectedData"
              :key="data.index"
              class="text item"
            >
              <template v-if="data.name === 'created_at'">
                {{ $t(`field.${data.name}`) }} : {{ UTCtoLocalTime(data.value) }}
              </template>
              <template v-if="data.name !== '_id' && data.name !== 'created_at'">
                {{ $t(`field.${data.name}`) }} : {{ data.value }}
              </template>
            </div>
          </el-card>
        </el-dialog>
      </div>
    </div>
    <!-- pagination -->
    <div class="pagination-container">
      <el-pagination
        :background="true"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pageLimit"
        :total="totalPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      v-loading="deleteDialogLoading"
      :element-loading-text="$t(`Notify.patientWhenDeleteData`)"
      :visible.sync="deleteDialogVisible"
      :title="$t('general.Warning')"
      width="30%"
      @close="deleteIds=[]"
    >
      <!-- 單筆刪除 -->
      <template v-if="deleteIds.length !== 0">
        <div class="single-delete delete-dialog-content">
          <div class="no-reverse">{{ $t('dataList.canNotReverse') }}</div>
          <div>{{ $t('dataList.isDelete') }}</div>
        </div>
      </template>
      <!-- 刪除筆數超過 2 萬筆 -->
      <template v-else-if="!allowBatchDelete">
        <div class="denied-delete delete-dialog-content">
          {{ $t('dataList.filterCount') }}{{ totalPage }} {{ $t('Dashboard.Records') }}
          <div class="notice-warning">{{ $t('dataList.massDeleteLimit') }}
            <br>
            {{ $t('dataList.connectUs') }}
          </div>
        </div>
      </template>
      <!-- 批次刪除 -->
      <template v-else>
        <div class="batch-delete delete-dialog-content">
          <div class="no-reverse">{{ $t('dataList.canNotReverse') }}</div>
          <div class="batch-delete-description">
            <span>
              {{ $t('dataList.deleteCountTotal') }} {{ totalPage }} {{ $t('Dashboard.Records') }}
            </span>
            <span>
              , {{ $t('dataList.pleaseEnter') }} {{ totalPage }} {{ $t('dataList.handleDeleteAction') }}
            </span>
          </div>
          <el-input
            v-model="inputNumber"
            :placeholder="$t('dataList.enterNumber')"
            @change="handleEnterDeleteNumber"
          />
          <div
            v-if="showWrongNumberNotify"
            class="notify-wrong-number"
          >
            {{ $t('dataList.wrongNumber') }}
          </div>
        </div>
      </template>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <template v-if="allowBatchDelete">
          <!-- 單筆刪除 -->
          <el-button
            v-if="deleteIds.length !== 0"
            plain
            type="danger"
            @click="confirmDelete"
          >
            {{ $t('general.Delete') }}
          </el-button>
          <!-- 多筆刪除 -->
          <el-button
            v-else
            plain
            type="danger"
            :disabled="stopDelete"
            @click="confirmDelete"
          >
            {{ $t('general.Delete') }}
          </el-button>
        </template>
        <el-button
          type="success"
          @click="deleteDialogVisible = false"
        >
          {{ $t('general.Cancel') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { FormatNumber, MemberRole, SuccessMessage } from '@/Mixins'
import { UTCtoLocalTime } from '@/utils/handleTime'
import SearchFilter from './components/SearchFilter.vue'
import { getOrganization } from '@/api/organization'
import {
  getExcelData,
  getInfoData,
  deleteData
} from '@/api/data'

const mainType = () => [
  'PhysicalFitness',
  'Sport',
  'Physiology'
]
const params = () => ({
  page: 1,
  main_type: mainType().toString(),
  page_limit: 20
  // version: 'all' // later
})

export default {
  name: 'DataListPage',
  components: {
    // DataList,
    SearchFilter
  },
  mixins: [FormatNumber, MemberRole, SuccessMessage],
  data () {
    return {
      batchDelete: require('@/assets/images/icon/batch_delete.svg'),
      listLoading: false,
      deleteDialogLoading: false,
      params: params(),
      mainType: mainType(),
      filterOptions: {
        mainType: [],
        type: [],
        subType: [],
        dateRange: [],
        record_dateRange: [],
        org: null,
        // versionId: [],
        userId: null
      },
      deleteNumber: {
        count: ''
      },
      inputNumber: 0,
      hasInputNumber: false,
      tableData: [],
      fieldsData: [],
      fieldsDataWithWidth: [],
      selectedData: [],
      orgOptionList: [],
      deleteDialogVisible: false,
      dialogVisible: false, // detail dialog
      deleteIds: [],
      currentPage: 1,
      pageLimit: 20,
      totalPage: 0
    }
  },
  computed: {
    showWrongNumberNotify() {
      const inputNumberAsNumber = Number(this.inputNumber)
      return inputNumberAsNumber !== this.totalPage && this.hasInputNumber
    },
    allowBatchDelete() {
      return !(this.totalPage > 20000 && this.deleteIds.length === 0)
    },
    stopDelete() {
      const inputNumberAsNumber = Number(this.inputNumber)
      return this.hasInputNumber === 0 || inputNumberAsNumber !== this.totalPage
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.fetchInfo()
      this.fetchData()
    },
    async fetchInfo() {
      const res = await getInfoData(this.params)
      const resultFields = res.data.required_columns
      this.fieldsData = [
        ...resultFields
      ]
      this.fieldsData = this.removeItems(this.fieldsData, ['name', 'created_at', 'timezone', 'place_type', 'birth_year', 'device_type', 'organization_name'])
      this.fieldsDataWithWidth = this.fieldsData.map(field => {
        if (field === 'city') {
          return {
            key: field,
            width: 100
          }
        } else if (field === 'main_type') {
          return {
            key: field,
            width: 100
          }
        } else if (field === 'type') {
          return {
            key: field,
            width: 130
          }
        } else if (field === 'subtype') {
          return {
            key: field,
            width: 100
          }
        } else if (field === 'gender') {
          return {
            key: field,
            width: 80
          }
        } else if (field === 'start_date') {
          return {
            key: field,
            width: 200
          }
        } else {
          return {
            key: field
          }
        }
      })
      if (this.isAdminTeam) {
        this.fetchGetOrganizationList()
      }
    },
    removeItems(arr, items) {
      return arr.filter(item => !items.includes(item))
    },
    async fetchData() {
      this.listLoading = true
      const res = await getExcelData(this.params)
      this.tableData = res.data.result
      this.totalPage = res.data.page.total
      this.listLoading = false
    },
    async fetchGetOrganizationList() {
      const res = await getOrganization()
      const data = res.data.organization_list
      this.orgOptionList = data.map((item) => {
        const newObj = {
          ...item,
          label: item.display,
          value: item.id
        }
        return newObj
      })
    },
    handleFilter() {
      this.params = params()
      if (this.filterOptions.mainType.length > 0) this.params.main_type = this.filterOptions.mainType.toString()
      if (this.filterOptions.type.length > 0) this.params.type = this.filterOptions.type.toString()
      if (this.filterOptions.subType.length > 0) this.params.subtype = this.filterOptions.subType.toString()
      if (this.filterOptions.org) this.params.organization_id = this.filterOptions.org
      if (this.filterOptions.userId) this.params.search = this.filterOptions.userId
      if (this.filterOptions.dateRange.length > 0) {
        this.params.start_date = this.filterOptions.dateRange[0]
        this.params.due_date = this.filterOptions.dateRange[1]
      }
      if (this.filterOptions.record_dateRange.length > 0) {
        this.params.record_start_date = this.filterOptions.record_dateRange[0]
        this.params.record_due_date = this.filterOptions.record_dateRange[1]
      }
      this.fetchData()
    },
    handleRowClick(row) {
      this.dialogVisible = true
      this.selectedData = []
      this.selectedId = row._id.$oid
      const noShow = [
        'organization_id',
        'orgin_object_id',
        'filename_hascode',
        'distance_m',
        'member_id',
        'data_hash',
        'member_name'
      ]
      const order = [
        'main_type',
        'type',
        'subtype',
        'name',
        'device_type',
        'place_type',
        'timezone',
        'start_date',
        'city',
        'gender',
        'birth_year',
        'user_id',
        'version'
      ]
      let i = 1
      for (const key in row) {
        if (!noShow.find(el => el === key)) {
          i++
          this.selectedData.push({
            name: key,
            value: row[key],
            order: order.find(el => el === key) ? order.findIndex(el => el === key) : i * 100
          })
        }
      }
      this.selectedData.sort((a, b) => a.order - b.order)
    },
    handleEnterDeleteNumber() {
      this.hasInputNumber = true
    },
    handleDelete() {
      this.deleteIds.push(this.selectedId)
      this.deleteDialogVisible = true
    },
    async confirmDelete () {
      this.deleteDialogLoading = true
      try {
        if (this.deleteIds.length === 0) {
          const filter = JSON.parse(JSON.stringify(this.params))
          delete filter.page
          delete filter.page_limit
          await deleteData(filter)
        } else {
          await deleteData({ ids: this.deleteIds })
        }
        this.showSuccessMessage(this.$t('Notify.Deleted'))
      } catch (error) {
        console.error(error)
      } finally {
        this.deleteDialogLoading = false
        this.deleteDialogVisible = false
        this.dialogVisible = false
        await this.fetchData()
      }
    },
    UTCtoLocalTime(time, format) {
      return UTCtoLocalTime(time, format)
    },
    // for pagination
    handleSizeChange(val) {
      this.params.page_limit = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.data-container {
  .delete-dialog-content{
    .no-reverse{
      color: $danger;    
      font-size: 1rem;
      line-height: 2;
      font-weight: bold;
    }
    .notify-wrong-number{
      color: $danger;
      margin: 8px 0;
    }
  }
  
  .data-container-main {
    position: relative;
    flex-direction: column;
    .el-table .el-button {
      border: none;
      background-color: transparent;
    }
  }
}

.data-bar {
  display:flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .control-left{
    text-align: left;
    .control-text{
      margin-left: 10px;
      font-size: 16px;
    }
  }
  .delete-button-container {
    display: flex;
    text-align: end;
    .delete-button {
      margin-left: 10px;
    }
    .batch-delete-button{
      padding: 12px 8px;
      margin: 0 8px;
      .image{
        height: 16px;
        display: block;
        margin: 0;
      }
    }
    .batch-delete-button:hover{
      background-color: #EBEBEB;
    }
  }
}

.data-list {
  width: 100%;
}

.data-detail {
  .box-card{
    text-align: left;
  }
  .el-dialog__header{
    display: none;
  }
  .text {
    font-size: 14px;
  }
  .item {
    margin-bottom: 18px;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
}
/* pagination */
.pagination-container {
  @include justifyCenter;
  .el-pagination {
    margin-top: 10px;
    background-color: #f3f2f1;
  }
}
</style>
